import { Logger } from '../../logging';
import { getToken } from '../../../utils/Storage';

class ApiService {
  static GET(url, headers = [[]]) {
    return this.makeRequest(url, 'GET', headers);
  }

  static POST(url, headers = [[]], body) {
    return this.makeRequest(url, 'POST', headers, body);
  }

  static PUT(url, headers = [[]], body) {
    return this.makeRequest(url, 'PUT', headers, body);
  }

  static DELETE(url, headers = [[]], body) {
    return this.makeRequest(url, 'DELETE', headers, body);
  }

  static FETCH(url, method = 'GET', headers = [[]], body, cancelPreviousRequest = false, withCredentials = true) {
    return this.makeRequest(url, method, headers, body, cancelPreviousRequest, withCredentials);
  }

  static activeXhrs = new Map();
  static isCancellationRequested = false;

  static async makeRequest(url, method, headers = [[]], body, cancelPreviousRequest = false, withCredentials = true) {
    let isFormFetch = body instanceof FormData;
    if (ApiService.isCancellationRequested) {
      ApiService.isCancellationRequested = false;
      return Promise.reject('Request cancelled');
    }

    if (cancelPreviousRequest && ApiService.activeXhrs.has(url)) {
      const xhr = ApiService.activeXhrs.get(url);
      xhr?.abort();
      ApiService.activeXhrs.delete(url);
    }

    const xhr = new XMLHttpRequest();
    ApiService.activeXhrs.set(url, xhr);

    return new Promise((resolve, reject) => {
      xhr.open(method, url);
      xhr.withCredentials = withCredentials;

      headers?.forEach((header) => {
        if (header[0] !== undefined && header[1] !== undefined) {
          xhr.setRequestHeader(header[0], header[1]);
          if(header[0] === 'content-type' && header[1] === 'multipart/form-data') {
            isFormFetch = true;
          }
        }
      });

      const token = getToken();
      if (!token) {
        Logger.log('Token is disabled.');
      } else {
        Logger.log('Token is enabled.');
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      }

      xhr.responseType = 'json';

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;

        ApiService.activeXhrs.delete(url);

        if (ApiService.isCancellationRequested) {
          ApiService.isCancellationRequested = false;
          reject('Request cancelled');
          return;
        }

        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            response: xhr.response,
          });
        }
      };

      xhr.onerror = () => {
        ApiService.activeXhrs.delete(url);

        if (ApiService.isCancellationRequested) {
          ApiService.isCancellationRequested = false;
          reject('Request cancelled');
          return;
        }

        reject({
          status: xhr.status,
          response: xhr.status,
        });
      };

      if (body) {
        if (isFormFetch) {
          xhr.send(body)
        } else {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(body));
        }
      } else {
        xhr.send();
      }
    });
  }

  static cancelActiveRequest() {
    if (ApiService.activeXhrs.size > 0) {
      ApiService.isCancellationRequested = true;
      ApiService.activeXhrs.forEach((xhr) => {
        xhr.abort();
      });
      ApiService.activeXhrs.clear();
    }
  }
}

const generateAuthorizedHeaders = async () => {
  const httpHeaders = new Headers();
  const token = getToken() || '';

  httpHeaders.append('Authorization', `Bearer ${token}`);
  httpHeaders.append('Accept', 'application/json');
  return httpHeaders;
};

const fetchFormData = () => {
  return [['content-type', 'multipart/form-data'], ['accept', 'application/json']];
};

const createRequestOptions = (headers, body) => ({
  method: 'POST',
  headers,
  body,
  redirect: 'follow',
});

const uploadPicture = async (url, values) => {
  const httpHeaders = await generateAuthorizedHeaders();
  const requestOptions = createRequestOptions(httpHeaders, values);
  return fetchData(url, requestOptions);
};

const fetchData = async (url, options) => {
  try {
    const response2 = await fetch(url, options);
    return response2;
  } catch (e) {
    Logger.log('fetch error:', e);
    return;
  }
};

const uploadWithFiles = async (url, values) => {
  try {
    const httpHeaders = await generateAuthorizedHeaders();
    const requestOptions = createRequestOptions(httpHeaders, values);

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Something went wrong');
    }

    return { ok: true, data };
  } catch (error) {
    Logger.log('fetch error:', error);
    const errorMessage = extractErrorMessage(error);
    return { ok: false, error: errorMessage };
  }
};

const extractErrorMessage = (error) => {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  }
  return 'Unknown error';
};

const toFormData = (item) => {
  const form_data = new FormData();

  for (const key in item) {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      form_data.append(key, item[key]);
    }
  }

  return form_data;
};

const toJson = (formData) => {
  const object = {};

  formData.forEach((value, key) => {
    object[key] = value.toString();
  });

  return object;
};

export { ApiService, generateAuthorizedHeaders, fetchFormData, uploadPicture, uploadWithFiles, toFormData, toJson };
