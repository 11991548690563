import Swal from "sweetalert2";
import { Endpoint } from "../endpoints";
import { ApiService, fetchFormData, toFormData } from "../networking";

export class InvitationService {

  static getList = (page = 1, limit = 10) => {
    return new Promise((resolve) => {
      ApiService.GET(`${Endpoint.InvitationList}?page=${page}&limit=${limit}`)
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            "GET USER LIST RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err });
        });
    });
  };

  static handleCRUD = async (id, method, body) => {
    return new Promise((resolve) => {
      ApiService.FETCH(method.toUpperCase() === 'POST' ? Endpoint.InvitationCreate : Endpoint.InvitationRUD.replace(":id", id), method, [[]], toFormData(body))
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            method.toUpperCase() + " INVITATION RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : err.status === 404 ? "Invitation Unavailable" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err });
        });
    });
  }

  static getDetails = (id) => {
    return this.handleCRUD(id, 'GET');
  };

  static create = (body) => {
    return this.handleCRUD(undefined, 'POST', body);
  };

  static update = (id, body) => {
    console.log("update")
    console.log(toFormData(body))
    return this.handleCRUD(id, 'PUT', body);
  };

  static delete = (id) => {
    return this.handleCRUD(id, 'DELETE');
  };
}