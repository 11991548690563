import React, { useCallback, useEffect, useState } from "react";
import FormSection from "components/Form/FormSection.js";
import Input from "components/Form/Input.js";
import Textarea from "components/Form/Textarea.js";
import Button from "components/Form/Button.js";
import Checkbox from "components/Form/Checkbox.js";
import { InvitationService } from "libs/api/services/InvitationService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router";
import Select from "components/Form/Select";
import { UserService } from "libs/api/services/UserService";

export default function InvitationForm({ existingData }) {
  const history = useHistory();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    userId: existingData?.userId || null,
    name: existingData?.name || "",
    slug: existingData?.slug || "",
    url: existingData?.url || "",
    previewUrl: existingData?.previewUrl || "",
    previewImage: existingData?.previewImage || "",
    music: existingData?.music || "",
    scrollSpeed: existingData?.scrollSpeed || "",
    useIframeFirst: existingData?.useIframeFirst || "",
    enableBlessing: existingData?.enableBlessing || "",
    enableGuestList: existingData?.enableGuestList || "",
    groom: existingData?.groom || "",
    bride: existingData?.bride || "",
    weddingLocation: existingData?.weddingLocation || "",
    weddingDate: existingData?.weddingDate || "",
    weddingTime: existingData?.weddingTime || "",
    mapImage: existingData?.mapImage || "",
    phone: existingData?.phone || "",
    email: existingData?.email || "",
    address: existingData?.address || "",
    title: existingData?.title || "",
    description: existingData?.description || "",
    isPublished: existingData?.isPublished || false,
    isArchived: existingData?.isArchived || false,
  });

  useEffect(() => {
    const getInvitationDetails = async (id) => {
      if (users && id) {
        const response = await InvitationService.getDetails(id);
        if (response.ok) {
          setFormData(response.data)
        }
      }
    }

    getInvitationDetails(id);
  }, [users, id])

  useEffect(() => {
    const getUsers = async () => {
      var response = await UserService.getUsers();
      if (response.ok) {
        setUsers(response.data);
      }
    }

    getUsers();
  }, [])

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: file,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    console.log("Form Data: ", formData); // Handle form submission
    e.preventDefault();
    if (formData.userId && formData.previewUrl && formData.name && formData.phone) {
      const response = id ? await InvitationService.update(id, formData) : await InvitationService.create(formData);
      if (response.ok) {
        history.push('/admin/invitations');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Invitation</h6>
                <Button
                  type={'submit'}
                  text="Save"
                // onClick={handleSubmit}
                />
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {/* Invitation Information */}
              <FormSection title="Invitation Information">
                <Select
                  label="User/Customer"
                  name="userId"
                  options={users.map(user => ({ label: `${user.name} (${user.email})`, value: user.id }))}
                  value={formData.userId}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required={"required"}
                />
                <Input
                  label="Name"
                  name="name"
                  defaultValue={formData.name}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                {/* <Input
                                        label="Slug"
                                        name="slug"
                                        defaultValue={formData.slug}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    /> 
                                    <Input
                                        label="URL"
                                        name="url"
                                        defaultValue={formData.url}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    /> */}
                <Input
                  label="Preview URL"
                  name="previewUrl"
                  defaultValue={formData.previewUrl}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Input
                  label="Groom's Name"
                  name="groom"
                  defaultValue={formData.groom}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Input
                  label="Bride's Name"
                  name="bride"
                  defaultValue={formData.bride}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Input
                  label="Wedding Location"
                  name="weddingLocation"
                  defaultValue={formData.weddingLocation}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Input
                  label="Wedding Date"
                  name="weddingDate"
                  type="date"
                  defaultValue={formData.weddingDate}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Input
                  label="Wedding Time"
                  name="weddingTime"
                  type="time"
                  defaultValue={formData.weddingTime}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />

                {/* Image Preview and Upload */}
                <div className="w-full lg:w-6/12 px-4 mb-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Preview Image
                  </label>
                  <div className="w-48 h-48 mb-4 border rounded flex justify-center items-center">
                    {formData.previewImage ? <img
                      src={formData.previewImage ? typeof formData.previewImage === "string" ? formData.previewImage : URL.createObjectURL(formData.previewImage) : ""}
                      alt="Preview"
                      className="object-cover w-full h-full object-top"
                    /> : <i className="fas fa-camera text-gray-500" style={{ fontSize: 30 }}></i>}
                  </div>
                  <Input
                    label=""
                    name="previewImage"
                    type="file"
                    onChange={handleChange}
                    wrapperClass="w-full"
                    inputClass="w-full"
                  />
                </div>
                {/* Music Upload */}
                <div className="w-full lg:w-6/12 px-4 mb-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Music
                  </label>
                  <div className="mb-4 rounded flex justify-center items-center">
                    {formData.music && (
                      <div>
                        {typeof formData.music === "string" ? (
                          <a href={formData.music} target="_blank" rel="noopener noreferrer">
                            {formData.music}
                          </a>
                        ) : (
                          <div>
                            {formData.music.name ? `Name: ${formData.music.name}` : ''}<br />
                            <a href={URL.createObjectURL(formData.music)} target="_blank" rel="noopener noreferrer">
                              URL: {URL.createObjectURL(formData.music)}
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <Input
                    label=""
                    name="music"
                    type="file"
                    onChange={handleChange}
                    wrapperClass="w-full"
                    inputClass="w-full"
                  />
                </div>
                <Input
                  label="Auto Scroll Speed"
                  name="scrollSpeed"
                  type="number"
                  min="0"
                  max="500"
                  placeholder="0 - 500"
                  defaultValue={formData.scrollSpeed}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
              </FormSection>

              {/* Contact Information */}
              <FormSection title="Contact Information">
                <Input
                  label="Phone"
                  name="phone"
                  defaultValue={formData.phone}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  defaultValue={formData.email}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                />
                <Textarea
                  label="Address"
                  name="address"
                  defaultValue={formData.address}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-12/12 px-4"
                  textareaClass="w-full"
                />
              </FormSection>

              {/* Other Information */}
              <FormSection title="Other Information">
                <Input
                  label="Title"
                  name="title"
                  defaultValue={formData.title}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                  inputClass="w-full"
                  required
                />
                <Textarea
                  label="Description"
                  name="description"
                  defaultValue={formData.description}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-12/12 px-4"
                  textareaClass="w-full"
                  required
                />
              </FormSection>

              {/* Status */}
              <FormSection title="Status">
                <Checkbox
                  label="Prefer iframe"
                  name="useIframeFirst"
                  checked={formData.useIframeFirst}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                />
                <Checkbox
                  label="Blessing Messages"
                  name="enableBlessing"
                  checked={formData.enableBlessing}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                />
                <Checkbox
                  label="Guest List"
                  name="enableGuestList"
                  checked={formData.enableGuestList}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                />
                <Checkbox
                  label="Published"
                  name="isPublished"
                  checked={formData.isPublished}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                />
                <Checkbox
                  label="Archived"
                  name="isArchived"
                  checked={formData.isArchived}
                  onChange={handleChange}
                  wrapperClass="w-full lg:w-6/12 px-4"
                />
              </FormSection>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
